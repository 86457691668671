<template>
    <div class="service-details">
        <div class="service-gallery">
            <div class="swiper-container service-gallery__container">
                <div class="swiper-wrapper service-gallery__wrapper" v-for="(service, index) in singleService" :key="index">
                    <swiper :options="swiperOptions">
                        <div class="swiper-slide service-gallery__single-slide" v-for="(image, imageIndex) in service.image" :key="imageIndex">
                            <div class="item">
                                <img :src="`/${ image.src }`" class="img-fluid" alt="service details image">
                            </div>
                        </div>
                    </swiper>
                </div>
            </div>
            <div class="ht-swiper-button-prev ht-swiper-button-prev-14 ht-swiper-button-nav"><i class="ion-ios-arrow-left"></i></div>
            <div class="ht-swiper-button-next ht-swiper-button-next-14 ht-swiper-button-nav"><i class="ion-ios-arrow-forward"></i></div>
        </div>

        <div class="content section-space--top--30">
            <div class="row">
                <div class="col-12" v-for="service in singleService" :key="service.id">
                    <h2>{{ service.title }}</h2>
                    <p v-html="service.desc">{{ service.desc }}</p>
                </div>
                <!-- <div class="col-lg-6 col-12 section-space--top--30" v-for="item in serviceDetails.serviceLists" :key="item.id">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.desc }}</p>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import data from '../data/service.json'

export default {
    props: ['serviceDetails', 'serviceDetails'],
    data () {
        return {
            data,
            singleService: [],
            swiperOptions: {
                slidesPerView : 1,
                loop: true,
                speed: 1000,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.ht-swiper-button-next-14',
                    prevEl: '.ht-swiper-button-prev-14'
                }
            }
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let data = this.data.serviceDetails.serviceDetailsMain
            return this.singleService = data.filter(item => {
                return item.id == this.id
            })
        },
    }
};
</script>
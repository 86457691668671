<template>
    <aside>
        <div class="sidebar-widget">
            <h3 class="sidebar-title">{{ serviceSidebar.title }}</h3>
            <ul class="sidebar-list">
                <li v-for="list in serviceSidebar.lists" :key="list.id">
                    <a :href="list.url">
                        <i :class="list.icon"></i> 
                        {{ list.name }}
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
export default {
    props: ['serviceSidebar'],
}
</script>